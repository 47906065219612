// 사용예시
    // sendEmail().then((response) => {
    //   console.log("res", response);
    // }).catch((error) => {
    //   console.log("err", error);
    // });


// Adding the following did break on POST method
    // headers: {
    //   "Content-Type": "application/json; charset=utf-8",
    //   'Accept': 'application/json'
    // },

function sendEmail(data) {
  // console.log(JSON.stringify(data));

  function getUrl () {
    if (window.location.host.includes("netlify") || window.location.host.includes("313"))
      return '/.netlify/functions/hello'
    else
      return 'http://127.0.0.1:9000/hello'
  }

  // Netlify Deploy
  return fetch(getUrl(), {
    method: "POST",
    body: JSON.stringify(data)
  }).then((res) => {
    // console.log("res", res);
    return res.json()
  }).catch((err) => {
    // console.log("err", err);
    return err.json()
  });
}


document.addEventListener('DOMContentLoaded', function (e) {
  let form = document.querySelector(".js-email-subs-form");
  let button = form.querySelector(".js-submit-email-subs");
  let messageWrapper = form.querySelector('.js-email-message-wrapper');

  function loadButton (state) {
    if (state === true) {
      // Load
      button.classList.add('js-loading');
      button.disabled = true
    } else if (state === false) {
      // Unload
      button.classList.remove('js-loading');
      button.disabled = false
    };
  };

  form.onsubmit = function (e) {
    e.preventDefault();

    loadButton(true);

    let data = {
      EMAIL: form.querySelector("[name='EMAIL']").value.trim(),
      FNAME: form.querySelector("[name='FNAME']").value.trim(),
      LNAME: form.querySelector("[name='LNAME']").value.trim()
    };

    if (data.EMAIL.length <= 0) {
      alert("Email cannot be empty")
      loadButton(false);
      form.querySelector("[name='EMAIL']").focus();
      return
    }

    if (data.FNAME.length <= 0) {
      alert("First Name cannot be empty")
      form.querySelector("[name='FNAME']").focus();
      loadButton(false);
      return
    }

    if (data.LNAME.length <= 0) {
      alert("Last Name cannot be empty")
      form.querySelector("[name='LNAME']").focus();
      loadButton(false);
      return
    }

    sendEmail(data).then((response) => {
      if (response.status === 'isSuccess' || response.status === 'alreadyMember') {
        console.log("Success")
        messageWrapper.classList.add('js-success');
        button.parentNode.removeChild(button);
      } else {
        if (typeof response === 'string') {
          alert(response);
        } else {
          console.log(response);
        }

        loadButton(false);
      }
    }).catch((error) => {
      console.log("err", error);
      messageWrapper.classList.remove('js-success');
      loadButton(false);
    });

  };
});